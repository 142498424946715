import * as React from "react";
import { PlasmicContract } from "../components/plasmic/gopanda/PlasmicContract";
import { Helmet } from "react-helmet"

function Contract() {
  // Use PlasmicContract to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicContract are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, PlasmicContract is wrapped by your project's global
  // variant context providers. These wrappers may be moved to
  // Gatsby "wrapRootElement" function
  // (https://www.gatsbyjs.com/docs/reference/config-files/gatsby-ssr#wrapRootElement).
  return (
           <>
           <Helmet>
             <title>代運合約</title>
                
           </Helmet>
           <PlasmicContract />
           </>
         );
}

export default Contract;
